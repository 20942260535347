<template>
  <div class="container">
    <nav class="breadcrumb is-large" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <router-link
            :to="{ name: 'account', params: { id: $route.params.id } }"
          >
            <span>Start</span></router-link
          >
        </li>
      </ul>
    </nav>
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <h1 class="card-header-title">
              {{ instanceConfig.payLessTitle }}
            </h1>
          </header>
          <div class="card-content">
            <div class="content">
              {{ instanceConfig.payLessContent }}
            </div>
          </div>
          <div class="card-footer">
            <router-link
              tag="button"
              class="card-footer-item button has-background-info has-text-white is-radiusless"
              :to="{
                name: 'negotiatorPayLess'
              }"
              >{{ instanceConfig.payLessButton }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="buttons">
        <router-link
          :to="{ name: 'makePayment', params: { id: $route.params.id } }"
          tag="button"
          class="button"
          >Cancel</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["instanceConfig"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig", "fetchUserInfo"]),
    ...mapActions("accounts", ["fetchAccounts"])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
      if (
        vm.$store.state.userInfo == null ||
        vm.$store.state.userInfo.firstName == null
      ) {
        vm.fetchUserInfo();
      }
      if (
        vm.$store.state.accounts.accounts == null ||
        vm.$store.state.accounts.accounts.length == 0
      ) {
        vm.fetchAccounts();
      }
    });
  }
};
</script>
